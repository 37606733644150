<template lang="pug">
.form-popup
  .form-popup__fields
    .form-popup__field
      label.input-text.input-text-gray
        svg.input-text__icon
          use(xlink:href="#form-field-name")
        input.input-text__input(
          type="text"
          placeholder="Ваше имя"
          v-model="fieldName"
        )
    .form-popup__field
      label.input-text.input-text-gray(
        :class="{'input-text-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
      )
        svg.input-text__icon
          use(xlink:href="#form-field-phone")
        input.input-text__input(
          type="tel"
          placeholder="Ваш телефон *"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )
    .form-popup__field
      label.input-textarea.input-textarea-gray
          textarea.input-textarea__input(
              placeholder="Сообщение"
              v-model="fieldText"
          )
    .form-popup__field
      .form-files
          .form-files__item
            input(
              type="file"
              name="files"
              ref="files"
              id="filesCalc"
              multiple
              style="display: none;"
              @change="onFileChange"
            )
            label.form-files__label(
              v-html="formFieldFilesButton"
              for="filesCalc"
            )
          .form-files__item
            label.form-files__result(v-html="fileResult")

  .form-popup__consent
    .consent
      input.consent__input(type="checkbox" id="form-checkbox-1" v-model="consent" value="consent" checked="checked")
      label.consent__label(for="form-checkbox-1" v-html="settingConsent.text")
  .form-popup__button
    .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
      .button__name(v-html="settings.button")
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopupCalc',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      settingConsent: {},
      send: false,
      fieldName: '',
      fieldPhone: '',
      fieldText: '',
      consent: ['consent'],
      formFieldFilesButton: 'Выберите файлы',
      fileResult: 'Файл не выбран',
      files: ''
    }
  },
  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
          this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
          this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
          this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        $('.button-popup--success').click()
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Рассчитать доставку"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
                title: 'Сообщение',
                value: this.fieldText
            },
            {
              title: 'ID',
              value: this.settings.fields.id
            },
            {
              title: 'Название страницы',
              value: this.settings.fields.pageName
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldText = ''

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.settings = window.appFundamenty1.FormPopupCalc
    this.settingConsent = window.appFundamenty1.FormPopupConsent
  }
}
</script>
