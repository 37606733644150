/*global $*/
/*eslint no-undef: "error"*/

export default class MenuMobile {
    classItem = 'menu-mobile__item'
    classItemOpen = 'menu-mobile__item-open'
    classButton = 'menu-mobile__button'

    classItemSub = 'menu-mobile-sub__item'
    classItemSubOpen = 'menu-mobile-sub__item-open'
    classButtonSub = 'menu-mobile-sub__button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })

        $(`.${self.classButtonSub}`).click(function () {
            $(this).closest(`.${self.classItemSub}`).toggleClass(self.classItemSubOpen)
        })
    }
}
