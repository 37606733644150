<template lang="pug">
.form
  .form__fields
    .form__field
      label.input-text(:class="{'input-text-focus': focusedName}")
        svg.input-text__icon
          use(xlink:href="#form-field-name")
        input.input-text__input(
          type="text"
          placeholder="Ваше имя"
          v-model="fieldName"
          @focus="focusedName = true"
          @blur="focusedName = false"
        )
    .form__field
      label.input-text(
        :class="{'input-text-focus': focusedPhone, 'input-text-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
      )
        svg.input-text__icon
          use(xlink:href="#form-field-phone")
        input.input-text__input(
          type="tel"
          placeholder="Ваш телефон *"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          @focus="focusedPhone = true"
          @blur="focusedPhone = false"
        )
    .form__field
      .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
        .button__name(v-html="settings.button")
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormMain',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      settingConsent: {},
      send: false,
      fieldName: '',
      fieldPhone: '',
      focusedName: false,
      focusedPhone: false,
      files: ''
    }
  },
  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        $('.button-popup--success').click()
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Заявка"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'ID',
              value: this.settings.fields.id
            },
            {
              title: 'Название страницы',
              value: this.settings.fields.pageName
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      }
    }
  },

  created () {
    this.settings = window.appFundamenty1.FormPopupMain
    this.settingConsent = window.appFundamenty1.FormPopupConsent
  }
}
</script>
