/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'

import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import tippy from 'tippy.js'

import SwiperSlider from './assets/js/Swiper/Swiper'

import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import HeaderFixed from '@/assets/js/Header/HeaderFixed/HeaderFixed'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import MenuProduct from '@/assets/js/Menu/MenuProduct/MenuProduct'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import FaqItem from '@/assets/js/Faq/FaqItem'

import form from '@/store/form'
import AppFormMain from '@/components/Form/Main/AppFormMain'
import AppFormSlider from '@/components/Form/Slider/AppFormSlider'
import AppFormPopupCalc from '@/components/Form/Popup/AppFormPopupCalc'
import AppFormPopupProduct from '@/components/Form/Popup/AppFormPopupProduct'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')

    tippy('.tippy--icon', {
        theme: 'material',
        arrow: true,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    if(document.getElementById('form--main') != null) {
        createApp(AppFormMain).use(form).use(Maska).mount('#form--main')
    }

    if(document.getElementById('form--slider') != null) {
        createApp(AppFormSlider).use(form).use(Maska).mount('#form--slider')
    }

    if(document.getElementById('form--calc') != null) {
        createApp(AppFormPopupCalc).use(form).use(Maska).mount('#form--calc')
    }

    if(document.getElementById('form--product') != null) {
        createApp(AppFormPopupProduct).use(form).use(Maska).mount('#form--product')
    }

    new SwiperSlider()
    new HeaderMobile()
    new HeaderFixed()
    new MenuMobile()
    new MenuProduct()
    new ShowHidden()
    new FaqItem()
})
