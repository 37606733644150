import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSliderFoundation () {
        return new Swiper('#slider--foundation', {
            slidesPerView: 5,
            spaceBetween: 0,
            navigation: {
                nextEl: '.slider--foundation-next',
                prevEl: '.slider--foundation-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 4
                },
                1240: {
                    slidesPerView: 5
                }
            }
        })
    }

    createSliderReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 3
                }
            }
        })
    }

    createSliderReviewVideo () {
        return new Swiper('#slider--review-video', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-video-next',
                prevEl: '.slider--review-video-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--foundation')!= null) {
            this.createSliderFoundation()
        }

        if(document.getElementById('slider--review')!= null) {
            this.createSliderReview()
        }

        if(document.getElementById('slider--review-video')!= null) {
            this.createSliderReviewVideo()
        }
    }
}
